#banner {
    background: url('../../images/banner.jpg');
    background-size: 100% 100%;
    color: #fff;
    padding: 30px 0px 95px;
}
.main-nav {
    height: auto;
    min-height: 75px;
}

.main-nav .navbar-right {
    margin-top: 22px;
}

.navbar-nav>li>a {
    color: #019000;
}
form {
    margin: 50px 0px 80px;
}
form h2{
	margin-bottom: 30px;
}
.copyright {
    padding: 20px 0px;
    border-top: 1px solid #e9e9e9;
    color: #666;
}
.navbar-toggle .icon-bar {
    background: #444;
}

.navbar-toggle {
    background: #e9e9e9;
    margin-top: 35px;
}

a.navbar-brand {
    height: 92px;
    padding: 15px 20px;
}

nav.navbar.main-nav {
    border-bottom: 1px solid gray;
    margin-bottom: 0px;
    padding-bottom: 20px;
}

.application-option {
    font-size: 1.7em;
    list-style-type: none;
    padding: 0;
    width: 100%;
    max-width: 390px;
    margin: 0 auto;
    margin-top: 48px;
}

.application-option li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
 }

.application-option li::before {
    position: absolute;
    content: "\f3a6";
    font-family: "Ionicons";
    left: 0;
    top: -3px;
    color: #fff;
}

.application-option li a{
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
}

.application-option li.active a{
    color: #FDDE23;
}

.application-option li.active::before {
    content: "\f3a7";
    color: #FDDE23;
}

.thumbnail > img{
    width: auto;
    height: 100px !important;
}

.remove-meta-file{
    cursor: hand;
}

.dashboard{

}

.dashboard{

}

.dashboard-item{
    text-align: center;

    padding: 20px;
}
.dashboard-item a{
    color: #4FB24F;
    text-decoration: none;
    font-weight: 300;
    /*font-size: 2em;*/
}

.dashboard-item .dashboard-item-icon{
    color: #959595;
    font-size: 2.5em;
    border: 1px solid #CFCFCF;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0px;
    border-radius: 5px;
}

.vertical-line-left {
    border-left: 1px solid #eeeeee;
}
.vertical-line-right{
    border-right: 1px solid #eeeeee;
}
 .note-editable{
     min-height: 300px !important;
 }
/*.nav-tabs > li.active > a {*/
    /*color: white;*/
    /*background-color: #337ab7;*/
/*}*/
/*.nav-tabs > li.active > a:hover,.nav-tabs > li.active > a:focus {*/
    /*color: white;*/
    /*background-color: #337ab7;*/
/*}*/

.remove_main_edu_div_btn, .remove_other_edu_div_btn, .remove_tertiary_edu_div_btn,.remove_work_exp_div_btn{
    margin-top: 24px;
}